// file: [appropriate_path]/LearnerAccountPanel.jsx
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, useCallback } from 'react';

import {
  FormAutocomplete,
  FormTextField,
  Button,
  FormActionWrapper,
  FormRadioGroup,
} from '~common/components';
import { validationSchemas, dataLists } from '~common/utils';
import checkEmailExists from '@patheducation/admin/src/utils/checkEmail.js';
import MuiPhone from '~common/components/Phone/MuiPhone.jsx';
import useStyles from './styles';
import BackButton from '../backButton';
import { formatFieldName } from '../helpers';

const { countryCodeOptions } = dataLists;

// Email regex pattern
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+(\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const LearnerAccountPanel = ({
  formData,
  onNextStep,
  submitOnPreviousStep,
  learnerIdxRef,
}) => {
  const { classes } = useStyles();

  const learnerEmailAddresses = formData?.learners.map((learner) => learner.emailAddress);

  const [noContactDetails, setNoContactDetails] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(validationSchemas.learnerAccountPanelSchema),
    context: {
      parentEmailAddress: formData?.emailAddress,
      learnerEmailAddresses,
    },
  });

  const emailField = formatFieldName('emailAddress', learnerIdxRef);
  const email = useWatch({
    control,
    name: emailField,
  });

  const isValidEmail = (email) => emailRegex.test(email);

  const debouncedCheckEmail = useCallback(
    (() => {
      let timer;
      return (email) => {
        clearTimeout(timer);
        timer = setTimeout(async () => {
          if (email && isValidEmail(email)) {
            try {
              const exists = await checkEmailExists(email);
              setEmailExists(exists);
              if (exists) {
                setError(emailField, {
                  type: 'manual',
                  message: 'This email is already in use',
                });
              } else {
                clearErrors(emailField);
              }
            } catch (error) {
              console.error('Error checking email:', error);
              setError(emailField, {
                type: 'manual',
                message: 'Error checking email availability',
              });
            }
          } else if (email) {
            setError(emailField, {
              type: 'manual',
              message: 'Please enter a valid email address',
            });
          } else {
            clearErrors(emailField);
          }
        }, 200);
      };
    })(),
    [setError, clearErrors, emailField],
  );

  useEffect(() => {
    debouncedCheckEmail(email);
  }, [email, debouncedCheckEmail]);

  useEffect(() => {
    const phonePrefixField = formatFieldName('phoneNumberPrefix', learnerIdxRef);
    if (getValues(phonePrefixField) === null) {
      setValue(phonePrefixField, {
        id: 'UK',
        value: '+44',
        name: 'United Kingdom',
      });
    }
  }, [getValues, setValue, learnerIdxRef]);

  useEffect(() => {
    reset(formData, {
      keepValues: true,
      resolver: yupResolver(validationSchemas.learnerAccountPanelSchema),
    });
  }, [noContactDetails, reset, formData]);

  const handleNoContactDetailsChange = (event) => {
    const isChecked = event.target.checked;
    setNoContactDetails(isChecked);

    const emailFieldName = formatFieldName('emailAddress', learnerIdxRef);
    const noDetailsField = formatFieldName('noDetails', learnerIdxRef);
    const phoneNumberField = formatFieldName('phoneNumber', learnerIdxRef);

    if (isChecked) {
      setValue(emailFieldName, '');
      setValue(noDetailsField, true);
      setValue(phoneNumberField, 1);
    } else {
      setValue(noDetailsField, false);
    }
  };

  const isBackButtonDisabled = getValues(formatFieldName('academicYear', learnerIdxRef)) === null;

  // Prepare phone input related variables
  const phonePrefixField = formatFieldName('phoneNumberPrefix', learnerIdxRef);
  const phoneNumberField = formatFieldName('phoneNumber', learnerIdxRef);
  const currentPrefixObj = getValues(phonePrefixField);
  const currentPrefix = (currentPrefixObj && currentPrefixObj.value) || '';
  const currentNumber = getValues(phoneNumberField) || '';
  const defaultCountry = currentPrefixObj && currentPrefixObj.id
    ? currentPrefixObj.id.toLowerCase()
    : 'gb';

  return (
    <form onSubmit={handleSubmit(onNextStep)} className={classes.formPanel} noValidate>
      <Grid container columnSpacing={1}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('firstName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner First Name',
              autoFocus: true,
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            name={formatFieldName('lastName', learnerIdxRef)}
            control={control}
            inputProps={{
              label: 'Learner Last Name',
              required: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={noContactDetails}
                onChange={handleNoContactDetailsChange}
                color="primary"
              />
            )}
            label="My child does not have contact details"
          />
        </Grid>

        {!noContactDetails && (
          <>
            <Grid item xs={12}>
              <FormTextField
                name={formatFieldName('emailAddress', learnerIdxRef)}
                control={control}
                inputProps={{
                  label: 'Learner Email Address',
                  required: true,
                  className: classes.emailInput,
                }}
                error={!!emailExists}
                helperText={emailExists ? 'This email is already in use' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiPhone
                country={defaultCountry}
                value={`+${currentPrefix}${currentNumber}`}
                containerStyle={{ marginBottom: '5px', marginTop: '10px', color: 'grey' }}
                inputStyle={{ marginBottom: '5px', marginTop: '10px' }}
                onChange={(data) => {
                  const dialCode = `+${data.country.dialCode}`;
                  // Determine correct country based on library provided country code
                  let matchedOption = null;
                  if (data.country.iso2) {
                    matchedOption = countryCodeOptions.find(
                      (option) => option.id.toLowerCase() === data.country.iso2.toLowerCase(),
                    );
                  }
                  // Fallback if country match not found using id, try matching dial code
                  if (!matchedOption) {
                    matchedOption = countryCodeOptions.find(
                      (option) => option.value === dialCode,
                    );
                  }
                  if (matchedOption) {
                    setValue(formatFieldName('phoneNumberPrefix', learnerIdxRef), matchedOption);
                  } else {
                    setValue(formatFieldName('phoneNumberPrefix', learnerIdxRef), { name: '', value: dialCode, id: '' });
                  }
                  // Remove prefix from value
                  let numberWithoutPrefix = data.phone.replace(dialCode, '');
                  // Remove any leading zeros if necessary
                  numberWithoutPrefix = numberWithoutPrefix.replace(/^0+/, '');
                  setValue(formatFieldName('phoneNumber', learnerIdxRef), numberWithoutPrefix);
                }}
                inputProps={{
                  name: 'phone',
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name={formatFieldName('ownAccount', learnerIdxRef)}
                label="Would you like the student to have access to their own account using their own log in details?"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FormActionWrapper>
            <BackButton
              onClick={submitOnPreviousStep}
              disabled={isBackButtonDisabled}
            />
            <Button
              type="submit"
              disabled={
                !noContactDetails && (emailExists || !isValidEmail(email))
              }
            >
              Next
            </Button>
          </FormActionWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

LearnerAccountPanel.propTypes = {
  formData: PropTypes.shape({
    emailAddress: PropTypes.string.isRequired,
    learners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  onNextStep: PropTypes.func.isRequired,
  submitOnPreviousStep: PropTypes.func.isRequired,
  learnerIdxRef: PropTypes.shape({
    current: PropTypes.number.isRequired,
  }).isRequired,
};

export default LearnerAccountPanel;
